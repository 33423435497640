
import { Component, Vue, Watch } from 'vue-property-decorator';
import { clone } from 'ramda';

import { Auth } from '@/store/modules';
import MeetingRow from '@/components/MeetingRow.vue';
import ClassRow from '@/components/ClassRow.vue';
import { User } from '@/store/auth';

@Component({
  components: {
    MeetingRow,
    ClassRow,
  },
})
export default class Profile extends Vue {
  model: any = {};
  loading = true;
  classHeaders = [
    { text: 'Name', value: 'name' },
    { text: 'Date', value: 'date' },
    { text: 'Rating', value: 'rating' }
  ];
  upcomingClasses: any[] = [];
  pastClasses: any[] = [];
  upcomingTeacherClasses: any[] = [];
  pastTeacherClasses: any[] = []
  tab = 0;

  @Watch('$route.query.tab')
  onTabQueryChanged() {
    if (this.$route.query.tab === 'teacher') {
      if (this.teacherId) {
        this.tab = 1;
      } else {
        this.$router.push('/start-educating')
      }
    } else {
      this.tab = 0;
    }
  }

  @Watch('tab')
  onTabChanged() {
    if (this.tab === 1) {
      this.$router.push({ query: { ...this.$route.query, tab: 'teacher' }});
    } else {
      this.$router.push({ query: { ...this.$route.query, tab: 'student' }});
    }
  }

  async mounted() {
    await this.refresh();
    this.onTabQueryChanged();
    if (this.$route.query.rate) {
      const classId = `class-${this.$route.query.rate as string}`;
      const ref = this.$refs[classId] as any;

      if (ref && !Array.isArray(ref)) {
        ref.openRateDialog();
      }

      if (ref && Array.isArray(ref) && ref[0]) {
        ref[0].openRateDialog();
      }
    }
  }

  async refresh() {
    this.loading = true;
    const user = await Auth.getSelfInformation();
    const upcomingClasses = (await this.$axios.get('/classes/upcoming')).data.items;
    const pastClasses = (await this.$axios.get('/classes/past')).data.items;
    this.upcomingClasses = clone(upcomingClasses).filter((course: any) => course.teacherId !== this.teacherId);
    this.pastClasses = clone(pastClasses).filter((course: any) => course.teacherId !== this.teacherId);
    if (this.teacherId) {
      this.upcomingTeacherClasses = clone(upcomingClasses).filter((course: any) => course.teacherId === this.teacherId);
      this.pastTeacherClasses = clone(pastClasses).filter((course: any) => course.teacherId === this.teacherId);
    }
    this.model = clone(user);
    this.loading = false;
  }

  get user() {
    return Auth.user;
  }

  get becomeEducator() {
    return this.$route.query.become_educator === 'true';
  }

  get educator() {
    return this.becomeEducator || this.model.role === 'teacher';
  }

  get teacherId() {
    return (Auth.user as User).teacherId;
  }

  async doLogout() {
    return Auth.doLogout();
  }
}
